import type { GetServerSideProps } from "next";
import styles from "./index.module.scss";
import { BusinessDetails, getBusinessDetails } from "../../api/home";
import React, { useCallback, useEffect, useState } from "react";
import { useGlobalStore } from "context/GlobalContext";
import InputForm from "components/UI/molecules/InputForm";
import Button from "components/UI/atoms/Button";
import ReCAPTCHA from "react-google-recaptcha";
import AuthModal from "components/UI/organisms/AuthModal";
import { authenticateUser } from "api/auth";
import { AxiosError } from "axios";
import showErrorToast from "utils/toast";
import router from "next/router";
import { getSubdomain } from "utils/utils";

interface Props extends BusinessDetails {}

const Login: React.FC<Props> = () => {
  const recaptchaRef: any = React.createRef();
  const { isLoggedIn } = useGlobalStore();
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const nextUrl = router.query.next as string;
    if (isLoggedIn) {
      if (nextUrl && nextUrl?.length > 0) {
        router.push(`/${nextUrl}`);
      } else {
        router.push("/");
      }
    }
  }, [isLoggedIn]);

  const handleSubmit = useCallback(
    async (captchaCode) => {
      setLoading(true);
      try {
        await authenticateUser(phone, captchaCode);
        setShowAuthModal(true);
      } catch (err) {
        const error = err as AxiosError;
        showErrorToast(error);
      }
      setLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phone]
  );

  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <div className={styles.login}>
          <h2>Login to continue</h2>
          <form
            className='d-flex flex-column'
            action='#'
            onSubmit={(e) => {
              e.preventDefault();
              recaptchaRef.current.execute();
            }}
          >
            <InputForm
              id='phone-number'
              type='tel'
              inputMode='tel'
              maxLength={11}
              required
              label='Phone number'
              placeholder='08012345678'
              onChange={(e) => {
                setPhone(e.target.value);
              }}
            />
            <ReCAPTCHA
              ref={recaptchaRef}
              size='invisible'
              sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY!}
              onChange={handleSubmit}
            />
            <p className={styles.disclaimer}>
              If you sign up,{" "}
              <a href='#'>Terms & Conditions and Privacy policy</a> apply
            </p>
            <Button
              className={styles.loginButton}
              disabled={loading}
              type='rounded'
              fullWidth
              label={loading ? "Loading..." : "Continue"}
            />
          </form>
        </div>
      </div>
      <AuthModal
        showOtp={true}
        propPhoneNumber={phone}
        showAuthModal={showAuthModal}
        onClose={() => setShowAuthModal(false)}
        color=''
      />
    </div>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  req,
  query
}) => {
  const subdomain = getSubdomain(req.headers.host!);
  let nextUrl;
  if (query.next) {
    nextUrl = query.next as string;
  } else {
    nextUrl = "/";
  }

  const { cookies } = req;
  if (subdomain && cookies) {
    if (cookies.token) {
      return {
        redirect: {
          destination: `/${nextUrl}`,
          permanent: false
        }
      };
    }
    let businessData = {};
    try {
      businessData = await getBusinessDetails(subdomain);

      return {
        props: {
          ...businessData
        }
      };
    } catch (error) {
      console.log(error); //server error
    }
  }

  return {
    notFound: true
  };
};

export default Login;
